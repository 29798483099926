@font-face {
  font-family: 'freeda-icon';
  src: url('../font/freeda-icon.eot?19174776');
  src: url('../font/freeda-icon.eot?19174776#iefix') format('embedded-opentype'),
       url('../font/freeda-icon.woff2?19174776') format('woff2'),
       url('../font/freeda-icon.woff?19174776') format('woff'),
       url('../font/freeda-icon.ttf?19174776') format('truetype'),
       url('../font/freeda-icon.svg?19174776#freeda-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'freeda-icon';
    src: url('../font/freeda-icon.svg?19174776#freeda-icon') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "freeda-icon";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-add:before { content: '\e800'; } /* '' */
.icon-arrow-down:before { content: '\e801'; } /* '' */
.icon-arrow-downward:before { content: '\e802'; } /* '' */
.icon-arrow-drop-down:before { content: '\e803'; } /* '' */
.icon-arrow-drop-up:before { content: '\e804'; } /* '' */
.icon-arrow-left:before { content: '\e805'; } /* '' */
.icon-arrow-right:before { content: '\e806'; } /* '' */
.icon-arrow-up:before { content: '\e807'; } /* '' */
.icon-barchart:before { content: '\e808'; } /* '' */
.icon-bookmark-full:before { content: '\e809'; } /* '' */
.icon-bookmark-outline:before { content: '\e80a'; } /* '' */
.icon-chart:before { content: '\e80b'; } /* '' */
.icon-check:before { content: '\e80c'; } /* '' */
.icon-close:before { content: '\e80d'; } /* '' */
.icon-delete:before { content: '\e80e'; } /* '' */
.icon-download:before { content: '\e80f'; } /* '' */
.icon-edit:before { content: '\e810'; } /* '' */
.icon-favorite:before { content: '\e811'; } /* '' */
.icon-filter-list:before { content: '\e812'; } /* '' */
.icon-help:before { content: '\e813'; } /* '' */
.icon-hidden:before { content: '\e814'; } /* '' */
.icon-info:before { content: '\e815'; } /* '' */
.icon-label:before { content: '\e816'; } /* '' */
.icon-link:before { content: '\e817'; } /* '' */
.icon-logout:before { content: '\e818'; } /* '' */
.icon-media:before { content: '\e819'; } /* '' */
.icon-menu:before { content: '\e81a'; } /* '' */
.icon-more-horiz:before { content: '\e81b'; } /* '' */
.icon-more-vert:before { content: '\e81c'; } /* '' */
.icon-move-to:before { content: '\e81d'; } /* '' */
.icon-open-link:before { content: '\e81e'; } /* '' */
.icon-play:before { content: '\e81f'; } /* '' */
.icon-remove:before { content: '\e820'; } /* '' */
.icon-replace:before { content: '\e821'; } /* '' */
.icon-reply:before { content: '\e822'; } /* '' */
.icon-save:before { content: '\e823'; } /* '' */
.icon-search:before { content: '\e824'; } /* '' */
.icon-show:before { content: '\e825'; } /* '' */
.icon-update:before { content: '\e826'; } /* '' */
.icon-upload:before { content: '\e827'; } /* '' */
.icon-expand:before { content: '\e828'; } /* '' */
.icon-reduce:before { content: '\e829'; } /* '' */
.icon-sidebar-moderation:before { content: '\e82a'; } /* '' */
.icon-comments:before { content: '\e82b'; } /* '' */
.icon-sidebar-posts:before { content: '\e82c'; } /* '' */